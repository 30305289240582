<template>
  <div class="index" :style="{ minHeight: minHeight }">
    <van-sticky>
      <van-nav-bar :placeholder="true">
        <template slot="title">
          <div class="toggle-buttons">
            <div
              :class="[
                'button',
                { selected: selectedButton === 'left' },
                'left-button',
              ]"
              @click="selectButton('left')"
            >
              按时间查看
            </div>
            <div
              :class="[
                'button',
                { selected: selectedButton === 'right' },
                'right-button',
              ]"
              @click="selectButton('right')"
            >
              按患者查看
            </div>
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>

    <van-search
      shape="round"
      background="#F6F6F6"
      v-model="searchValue"
      @search="searchOk"
      @clear="
        () => {
          this.getList();
        }
      "
      :placeholder="
        selectedButton === 'left'
          ? '输入医学公式关键词进行搜索'
          : '输入患者昵称关键字搜索'
      "
    />

    <div v-if="list.length !== 0">
      <!--按时间查看-->
      <div v-if="selectedButton === 'left'">
        <div class="card" v-for="(item, index) in list" :key="item.id">
          <div style="flex: 1;min-height: 40px" @click="logBtn(item)">
            <div class="swipe_title">
              {{ item.chineseName }}
            </div>
            <div class="DisplayResults">
              <span> {{ item.result.DisplayResults }}</span>
            </div>
            <div class="swipe_title_sub">
              {{ dateType(item.createdTime, 0) }}
            </div>
          </div>

          <van-icon
            style="margin-bottom: -20px"
            size="0.8rem"
            color="#CAC9C9"
            name="ellipsis"
            @click="toggleMenu(index, $event)"
          />

          <div
            v-if="menuVisible && selectedIndex === index"
            :style="{ top: menuPosition.top + 'px' }"
            class="popup-menu"
            :ref="'popupMenu' + index"
          >
            <div @click="deleteItem(item)">删除</div>
          </div>
        </div>
      </div>

      <!--按患者查看-->
      <div v-if="selectedButton === 'right'">
        <div class="card" v-for="(item, index) in list" :key="item.id">
          <div
            style="display: flex;align-items: center;flex: 1"
            @click="goBtn(item)"
          >
            <div
              class="card_man"
              :style="{ borderColor: item.sex === 1 ? '#47A3CC' : '#FF3887' }"
            >
              <img
                style="height: 100%;width: 100%"
                :src="item.sex === 1 ? man : woman"
                alt=""
              />
            </div>
            <div>
              <div class="card_text" style="margin-bottom: 5px">
                <span class="card_name">{{ maskChineseName(item.name) }}</span
                >&nbsp;{{ item.sex === 1 ? "男" : "女"
                }}<span v-if="item.age !== -1">/{{ item.age + "岁" }}</span
                ><span
                  v-if="
                    item.hasOwnProperty('patientNumber') &&
                      item.patientNumber !== ''
                  "
                  >/{{ item.patientNumber }}</span
                >
              </div>
              <div class="card_text" style="display: inline-flex">
                <span style="white-space: nowrap;">{{
                  dateType(item.finishTime, 1)
                }}</span
                >&nbsp;
                <span v-if="isDate(item.finishTime) === 1">
                  <span
                    class="card_title"
                    v-if="item.hasOwnProperty('chineseName')"
                    >完成&nbsp;{{ item.chineseName }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <van-icon
            style="margin-top: -10px"
            size="0.8rem"
            color="#CAC9C9"
            name="ellipsis"
            @click="toggleMenu(index, $event)"
          />

          <div
            v-if="menuVisible && selectedIndex === index"
            :style="{ top: menuPosition.top + 'px' }"
            class="popup-menu"
            :ref="'popupMenu' + index"
          >
            <div style="margin-bottom: 8px" @click="editItem(index, item)">
              编辑
            </div>
            <div @click="deleteItem(item)">删除</div>
          </div>
        </div>
      </div>
      <div v-if="menuVisible" class="overlay" @click="closeMenu"></div>
    </div>
    <van-empty v-else description="暂无内容" />

    <medical-form
      :visible="formShow"
      :editData="editForm"
      :member-id="memberId"
      :type="'edit'"
      @close="hideStudent"
    >
    </medical-form>
    <login v-model="loginShow" :title="loginTitle"> </login>
  </div>
</template>
<script>
import login from "@/components/login.vue";
import { Dialog, Toast } from "vant";
import medicalForm from "@/components/medicalForm.vue";
import {
  delMedicalLog_patient,
  delMedicalLog_time,
  getMedicalLog_patient,
  getMedicalLog_time,
} from "@/service/api";
import moment from "moment/moment";
// import { postDataBrowse } from "@/service/api";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
export default {
  name: "medicalMark",
  components: {
    medicalForm,
    login,
  },
  data() {
    return {
      man: require("@/assets/medical/male_icon.png"),
      woman: require("@/assets/medical/woman_icon.png"),
      memberId: this.$store.state.memberIdDocument,
      formShow: false,
      searchValue: "",
      minHeight: window.innerHeight - 100 + "px",
      selectedButton: this.$store.state.medicalMenu_log,
      menuVisible: false,
      menuPosition: {
        top: 0,
      },
      selectedIndex: null,
      list: [],
      editForm: {},
      pageNo: 1,
      pageSize: 10,
      activeName: null,
      formulaCode: null,
      detailList: [],
      loading: false,
      finished: false,
      addBtn: false,
      loginShow: false,
      loginTitle: "",
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    if (this.$store.state.memberIdDocument == "") {
      this.loginShow = true;
      this.loginTitle = "登录查看更多内容";
    }
    // this.getById()
  },

  watch: {
    $route(now) {
      console.log(now);
      this.list = [];
      this.pageNo = 1;
      this.getById();
      this.getList();
    },
  },
  mounted() {
    this.$store.commit("medicalMenu_typeIndex", 0);
    document.addEventListener("click", this.handleClickOutside);
    this.getList();
  },

  methods: {
    async getById() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$store.state.memberIdDocument,
        this.$route.query.channel
      );
      await setById(arrays);
      await this.getList();
      //  console.log(this.channel);
    },
    handleClickOutside(event) {
      if (event.target.className.includes("van-icon van-icon-ellipsis")) {
        console.log(1);
      } else {
        this.selectedIndex = "随机数";
        this.menuVisible = false;
      }
    },

    maskChineseName(name) {
      // 检查姓名长度
      const length = name.length;

      if (length <= 1) {
        // 如果姓名长度小于等于1，直接返回原名
        return name;
      } else if (length === 2) {
        // 如果姓名长度为2，只替换最后一个字符
        return name[0] + "*";
      } else {
        // 如果姓名长度大于2，替换中间字符
        const firstChar = name[0];
        const lastChar = name[length - 1];
        const middleAsterisks = "*".repeat(length - 2);

        return firstChar + middleAsterisks + lastChar;
      }
    },
    isDate(date) {
      const start = moment()
        .add(-7, "d")
        .format("YYYY-MM-DD");
      return moment(date).format("YYYY-MM-DD") === start ? 0 : 1;
    },

    dateType(date, type) {
      const start = moment()
        .add(-7, "d")
        .format("YYYY-MM-DD");
      return type === 0
        ? moment(date).format("MM月DD日 HH:mm")
        : moment(date).format("YYYY-MM-DD") === start
        ? "近一周无评分记录"
        : moment(date).format("MM月DD日 HH:mm");
    },
    searchOk() {
      this.getList();
    },

    async getList() {
      const res =
        this.selectedButton === "left"
          ? await getMedicalLog_time(this.searchValue)
          : await getMedicalLog_patient(this.searchValue);

      if (res.statuscode === 200) {
        this.list = res.data;
      }
    },

    selectButton(button) {
      this.menuVisible = false;
      this.selectedIndex = null;
      this.list = [];
      this.selectedButton = button;
      this.$store.commit("medicalMenu_log", button);
      this.getList();
    },
    toggleMenu(index, event) {
      if (this.selectedIndex === index && this.menuVisible) {
        // 如果当前菜单已经打开，则关闭它
        this.menuVisible = false;
        this.selectedIndex = null;
      } else {
        // 否则，打开菜单并设置位置
        this.selectedIndex = index;
        this.menuVisible = true;
        this.$nextTick(() => {
          const rect = event.target.getBoundingClientRect();
          this.menuPosition.top = rect.bottom + window.scrollY;
        });
      }
    },
    closeMenu() {
      this.menuVisible = false;
      this.selectedIndex = null;
    },
    editItem(index, row) {
      this.editForm = row;
      this.formShow = true;
      this.menuVisible = false;
      this.selectedIndex = null;
    },
    async deleteItem(row) {
      Dialog.confirm({
        title: "是否确认删除？",
        confirmButtonText: "是",
        cancelButtonText: "否",
      })
        .then(async () => {
          const del =
            this.selectedButton === "left"
              ? await delMedicalLog_time(row.id)
              : delMedicalLog_patient(row.id);
          if (del.statuscode === 200) {
            Toast.success("删除成功");
            this.getList();
          } else {
            Toast.fail(del.message);
          }

          // on confirm
        })
        .catch(() => {
          // on cancel
        });

      this.menuVisible = false;
      this.selectedIndex = null;
    },
    hideStudent() {
      this.formShow = false;
      this.getList();
    },
    logBtn(item) {
      console.log();
      if (this.menuVisible === true) {
        this.menuVisible = false;
        this.selectedIndex = null;
        return;
      }

      this.$router.push({
        path: "/formula/" + item.code,
        query: {
          logId: item.id,
          id: item.formulaId,
          memberId: this.$store.state.memberIdDocument,
          channel: this.$route.query.channel,
          type: "h5",
          location: "YXGS_RECORD_LIST",
        },
      });
    },
    goBtn(row) {
      if (this.menuVisible === true) {
        this.menuVisible = false;
        this.selectedIndex = null;
        return;
      }
      row.memberId = this.$store.state.memberIdDocument;
      row.channel = this.$route.query.channel;
      this.$store.commit("editForm", JSON.stringify(row));

      this.$router.push({
        name: "patientLog",
        params: {},
      });
    },
  },
};
</script>

<style scoped lang="scss">
.DisplayResults {
  margin-top: 5px;
  font-size: 13px;
  color: #2063aa;
  font-weight: 600;
}
.swipe_title_sub {
  margin-top: 5px;
  color: #999999;
  font-size: 13px;
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 1;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
}
.card_title {
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 1;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
}
.swipe_title {
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 2;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
}
.index {
  padding-bottom: 100px;
  background: white;
  height: auto;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: #f6f6f6 1px solid;
  }
}
::v-deep .van-search__content {
  background: white !important;
}
.toggle-buttons {
  font-size: 14px;
  display: flex;
  border-radius: 24px 24px 24px 24px;
  border: 1px solid #00bdff;
}

.button {
  flex: 1;
  padding: 3px 10px;
  text-align: center;
  cursor: pointer;
}

.left-button {
  border-radius: 24px 0 0 24px;
}

.right-button {
  border-radius: 0 24px 24px 0;
}

.button.selected {
  background-color: #00bdff;
  color: white;
}

.button:not(.selected) {
  background-color: white;
  color: #00bdff;
}

.left-button.selected {
  border-right: 1px solid #00bdff;
  border-radius: 24px;
}

.right-button.selected {
  border-left: 1px solid #00bdff;
  border-radius: 24px;
}

.popup-menu {
  position: absolute;
  background: white;
  border: 1px solid #f1f1f1;
  padding: 5px 8px;
  right: 2px;
  font-size: 14px;
  z-index: 1000; /* 确保下拉框在最前面 */
}
.list-item {
  position: relative; /* 使下拉框相对于列表项定位 */
  margin-bottom: 20px; /* 添加间距以避免重叠 */
}
.card_man {
  padding: 3px;
  width: 30px;
  height: 30px;
  border: #47a3cc 1px solid;
  border-radius: 6px;
  margin-right: 10px;
}
.card_text {
  font-size: 14px;
  color: #999999;
  .card_name {
    font-size: 15px;
    color: #333333;
  }
}
</style>
