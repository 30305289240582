var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index",style:({ minHeight: _vm.minHeight })},[_c('van-sticky',[_c('van-nav-bar',{attrs:{"placeholder":true}},[_c('template',{slot:"title"},[_c('div',{staticClass:"toggle-buttons"},[_c('div',{class:[
              'button',
              { selected: _vm.selectedButton === 'left' },
              'left-button' ],on:{"click":function($event){return _vm.selectButton('left')}}},[_vm._v(" 按时间查看 ")]),_c('div',{class:[
              'button',
              { selected: _vm.selectedButton === 'right' },
              'right-button' ],on:{"click":function($event){return _vm.selectButton('right')}}},[_vm._v(" 按患者查看 ")])])])],2)],1),_c('van-search',{attrs:{"shape":"round","background":"#F6F6F6","placeholder":_vm.selectedButton === 'left'
        ? '输入医学公式关键词进行搜索'
        : '输入患者昵称关键字搜索'},on:{"search":_vm.searchOk,"clear":function () {
        this$1.getList();
      }},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),(_vm.list.length !== 0)?_c('div',[(_vm.selectedButton === 'left')?_c('div',_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"card"},[_c('div',{staticStyle:{"flex":"1","min-height":"40px"},on:{"click":function($event){return _vm.logBtn(item)}}},[_c('div',{staticClass:"swipe_title"},[_vm._v(" "+_vm._s(item.chineseName)+" ")]),_c('div',{staticClass:"DisplayResults"},[_c('span',[_vm._v(" "+_vm._s(item.result.DisplayResults))])]),_c('div',{staticClass:"swipe_title_sub"},[_vm._v(" "+_vm._s(_vm.dateType(item.createdTime, 0))+" ")])]),_c('van-icon',{staticStyle:{"margin-bottom":"-20px"},attrs:{"size":"0.8rem","color":"#CAC9C9","name":"ellipsis"},on:{"click":function($event){return _vm.toggleMenu(index, $event)}}}),(_vm.menuVisible && _vm.selectedIndex === index)?_c('div',{ref:'popupMenu' + index,refInFor:true,staticClass:"popup-menu",style:({ top: _vm.menuPosition.top + 'px' })},[_c('div',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("删除")])]):_vm._e()],1)}),0):_vm._e(),(_vm.selectedButton === 'right')?_c('div',_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"card"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex":"1"},on:{"click":function($event){return _vm.goBtn(item)}}},[_c('div',{staticClass:"card_man",style:({ borderColor: item.sex === 1 ? '#47A3CC' : '#FF3887' })},[_c('img',{staticStyle:{"height":"100%","width":"100%"},attrs:{"src":item.sex === 1 ? _vm.man : _vm.woman,"alt":""}})]),_c('div',[_c('div',{staticClass:"card_text",staticStyle:{"margin-bottom":"5px"}},[_c('span',{staticClass:"card_name"},[_vm._v(_vm._s(_vm.maskChineseName(item.name)))]),_vm._v(" "+_vm._s(item.sex === 1 ? "男" : "女")),(item.age !== -1)?_c('span',[_vm._v("/"+_vm._s(item.age + "岁"))]):_vm._e(),(
                  item.hasOwnProperty('patientNumber') &&
                    item.patientNumber !== ''
                )?_c('span',[_vm._v("/"+_vm._s(item.patientNumber))]):_vm._e()]),_c('div',{staticClass:"card_text",staticStyle:{"display":"inline-flex"}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.dateType(item.finishTime, 1)))]),_vm._v("  "),(_vm.isDate(item.finishTime) === 1)?_c('span',[(item.hasOwnProperty('chineseName'))?_c('span',{staticClass:"card_title"},[_vm._v("完成 "+_vm._s(item.chineseName)+" ")]):_vm._e()]):_vm._e()])])]),_c('van-icon',{staticStyle:{"margin-top":"-10px"},attrs:{"size":"0.8rem","color":"#CAC9C9","name":"ellipsis"},on:{"click":function($event){return _vm.toggleMenu(index, $event)}}}),(_vm.menuVisible && _vm.selectedIndex === index)?_c('div',{ref:'popupMenu' + index,refInFor:true,staticClass:"popup-menu",style:({ top: _vm.menuPosition.top + 'px' })},[_c('div',{staticStyle:{"margin-bottom":"8px"},on:{"click":function($event){return _vm.editItem(index, item)}}},[_vm._v(" 编辑 ")]),_c('div',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("删除")])]):_vm._e()],1)}),0):_vm._e(),(_vm.menuVisible)?_c('div',{staticClass:"overlay",on:{"click":_vm.closeMenu}}):_vm._e()]):_c('van-empty',{attrs:{"description":"暂无内容"}}),_c('medical-form',{attrs:{"visible":_vm.formShow,"editData":_vm.editForm,"member-id":_vm.memberId,"type":'edit'},on:{"close":_vm.hideStudent}}),_c('login',{attrs:{"title":_vm.loginTitle},model:{value:(_vm.loginShow),callback:function ($$v) {_vm.loginShow=$$v},expression:"loginShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }